
import React from 'react';
import AsideLayout from '../components/AsideLayout';
import { graphql } from 'gatsby'
import { padded, center } from '../styles/util';
import Button, {ButtonLink} from '../components/Button';
import parser from 'html-react-parser'

function CsaPage({data}) {
  const {html, frontmatter: {title, description, forms}} = data.file.childMarkdownRemark;

  return (
    <AsideLayout title={title} description={description}>
      <p css={center}> <ButtonLink primary="1" to="/shop">Buy Online</ButtonLink> </p>

      <p><b>Or</b> download a PDF membership form:</p>

      <ul css={{marginTop: 0}}>
        {forms.map(({title, file}) => 
          <li key={title}>
            <a href={file.publicURL} target="_blank">{title}</a>
          </li>)}
      </ul>

      {parser(html)}
    </AsideLayout>
  );
}

export default CsaPage;


export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "pages/csa.md"}) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          forms {
            title
            file {
              publicURL
            }
          }
        }
        html
      }
    }
  }
`;